const axios = require("axios");
// const oauth = require('axios-oauth-client');

var ClientOAuth2 = require("client-oauth2");

var oauth2 = new ClientOAuth2({
  clientId: "virty",
  clientSecret: "7ad0f518-5a6e-424d-8dcd-fad40507a4a5",
  accessTokenUri:
    "https://sso.zionbit.cz/auth/realms/zionbit/protocol/openid-connect/token",
  authorizationUri:
    "https://sso.zionbit.cz/auth/realms/zionbit/protocol/openid-connect/auth",
  redirectUri: window.location.origin,
  scopes: ['openid', 'profile', 'roles']
});


export async function checkAuth() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("code")) {
      console.log(`Code in query: ${urlParams.get("code")}`);
      oauth2.code.getToken(window.location).then(function(token) {
          console.log(`Saving token: ${token.accessToken}`)
          localStorage.setItem("authToken", token.accessToken);
          localStorage.setItem("refreshToken", token.refreshToken);
      });
    } else {
      if (localStorage.getItem("authToken")) {
    const authToken = localStorage.getItem("authToken");

    try {
      const userInfo = await axios.get(
        "https://sso.zionbit.cz/auth/realms/zionbit/protocol/openid-connect/userinfo",
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      console.log(userInfo);
      if (userInfo.status === 200) {
        return userInfo.data;
      }
    } catch (error) {
      console.error(error);
      console.log("failed, try refresh");
      var token = oauth2.createToken(localStorage.getItem("authToken"), localStorage.getItem("refreshToken"), 'bearer');
      // const test = { refreshToken: localStorage.getItem("refreshToken") };
      try {
        const newToken = await token.refresh();
        localStorage.setItem("authToken", newToken.accessToken);
        localStorage.setItem("refreshToken", newToken.refreshToken);
      } catch (error){
        console.log(error)
        console.log("REDIRECTIIIINGGG")    
        const uri = oauth2.code.getUri();
        window.location.replace(uri);
      }

    }

    // console.log(`Token in storage: ${token}`);
    return true;
  } else { 
      const uri = oauth2.code.getUri();
      console.log(`Token not found, redirecting to ${uri}`);
      window.location.replace(uri);
    }
  }

  // auth();
  // if(localStorage.getItem("authToken")){
  //   return true;
  // } else {
  // console.log("NOT FOUND");
  //   const authToken = await getAuthorizationCode();
  //   console.log(authToken);
  //   localStorage.setItem("authToken", authToken);
  // }
}
//
// /* eslint-disable */
// import Oidc from "oidc-client";
// //import 'babel-polyfill';
//
// var mgr = new Oidc.UserManager({
//   userStore: new Oidc.WebStorageStateStore(),
//   authority: "https://sso.zionbit.cz/auth/realms/zionbit/",
//   client_id: "virty",
//   client_secret: "7ad0f518-5a6e-424d-8dcd-fad40507a4a5",
//   redirect_uri: window.location.origin,
//   response_type: "code",
//   scope: 'profile roles offline_access',
//   post_logout_redirect_uri: window.location.origin,
//   silent_redirect_uri: window.location.origin +'#sr',
//   accessTokenExpiringNotificationTime: 10,
//   // silentRequestTimeout: 0,
//   automaticSilentRenew: false,
//   filterProtocolClaims: true,
//   loadUserInfo: true
// });
//
// Oidc.Log.logger = console;
// export async function renew(){
//     return mgr.signinSilentCallback();
// }
//
//
// export async function signInCallback(){
// const result = await mgr
//   .signinRedirectCallback()
//   .catch(function(err) {
//     console.log(err);
//   });
//
//
//   console.log("redirect callback result")
//   console.log(result);
//
//   // const user = await ss.getProfile();
//   // console.log(result.profile);
//     // mgr.signinSilentCallback();
//   return result.profile;
// }
//
// export async function AcheckAuth() {
//   // var mgr = new Oidc.UserManager({userStore: new Oidc.WebStorageStateStore(), loadUserInfo: true, filterProtocolClaims: true});
//
//   // const ss = new SecurityService();
//
//   // let user = await ss.getProfile();
//
//
//   // mgr.stopSilentRenew();
//   const user = await mgr.getUser();
//
//   console.log(user)
//   if (user){
//     console.log("user logged")
//     return user.profile;
//   }
//   console.log("user not found > redirect")
//   mgr.signinRedirect().catch(function(err) {
//         console.log(err);
//       });
//   // const urlParams = new URLSearchParams(window.location.search);
//   // if (window.location.hash) {
//   //   console.log("HASH FOUND");
//   //   const result = await mgr
//   //     .signinRedirectCallback()
//   //     .catch(function(err) {
//   //       console.log(err);
//   //     });
//   //
//   //
//   //     console.log("redirect callback result")
//   //     console.log(result);
//   //
//   //     // const user = await ss.getProfile();
//   //     // console.log(result.profile);
//   //       // mgr.signinSilentCallback();
//   //     return result.profile;
//   //
//   // } else {
//   //   console.log("no hash");
//   //
//   //     mgr.signinRedirect().catch(function(err) {
//   //       console.log(err);
//   //     });
//   //   // const user = await ss.getProfile();
//   //   // if(!user){
//   //   //   console.log("sign in redirect here")
//   //   //   // ss.getSignedIn();
//   //   // }
//   //   // return user;
//   //   // ss.getSignedIn()
//   // }
//   // console.log(ss.getProfile());
// }
//
// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.INFO;
//
// mgr.events.addUserLoaded(function(user) {
//   console.log("New User Loaded：", arguments);
//   console.log("Acess_token: ", user.access_token);
// });
//
// mgr.events.addAccessTokenExpiring(function() {
//   console.log("AccessToken Expiring：", arguments);
// });
//
// mgr.events.addAccessTokenExpired(function() {
//   console.log("AccessToken Expired：", arguments);
//   alert("Session expired. Going out!");
//   mgr
//     .signoutRedirect()
//     .then(function(resp) {
//       console.log("signed out", resp);
//     })
//     .catch(function(err) {
//       console.log(err);
//     });
// });
//
// mgr.events.addSilentRenewError(function() {
//   console.error("Silent Renew Error：", arguments);
// });
//
// mgr.events.addUserSignedOut(function() {
//   alert("Going out!");
//   console.log("UserSignedOut：", arguments);
//   mgr
//     .signoutRedirect()
//     .then(function(resp) {
//       console.log("signed out", resp);
//     })
//     .catch(function(err) {
//       console.log(err);
//     });
// });
