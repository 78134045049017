<template>
  <div class="hello container">
    <h1>{{ msg }}</h1>

    <h3>Přehled nejbližších rezervací</h3>
    <table
      class="table table-hover table-striped table-sm"
      v-if="bookings != null && false"
    >
      <thead>
        <tr>
          <td>Datum</td>
          <td>Čas</td>
          <td>Jméno</td>
          <td>Atrakce</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="booking in test(bookings)"
          v-bind:key="booking._id"
          v-on:click="setBookingDetail(booking)"
        >
          <td>
            {{ new Date(booking.bookingStart).toLocaleDateString("cs-CZ") }}
          </td>
          <td>
            {{ ("0" + new Date(booking.bookingStart).getHours()).slice(-2) }}:{{
              ("0" + new Date(booking.bookingStart).getMinutes()).slice(-2)
            }}
            - {{ ("0" + new Date(booking.bookingEnd).getHours()).slice(-2) }}:{{
              ("0" + new Date(booking.bookingEnd).getMinutes()).slice(-2)
            }}

            (<span v-if="booking.hours != 0">{{ booking.hours }} hod.</span>
            <span v-if="booking.hours != 0 && booking.minutes != 0"
              >&nbsp;</span
            >
            <span v-if="booking.minutes != 0"> {{ booking.minutes }} min.</span
            >)
          </td>
          <td>
            {{ booking.user.fullName }}
          </td>
          <td>
            {{ booking.asset.name }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detail rezervace</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" v-if="bookingDetail">
            <div class="h4 mt-2">Uživatel</div>
            <b>Jméno</b>: {{ bookingDetail.user.fullName }}<br />
            <b>Telefon</b>: {{ bookingDetail.user.phone }}<br />
            <b>E-mail</b>: {{ bookingDetail.user.email }}<br />
            <div class="h4 mt-4">Rezervace</div>
            <b>Datum</b>:
            {{ new Date(bookingDetail.bookingStart).toLocaleDateString("cs-CZ")
            }}<br />
            <b>Čas</b>:
            {{
              ("0" + new Date(bookingDetail.bookingStart).getHours()).slice(-2)
            }}:{{
              ("0" + new Date(bookingDetail.bookingStart).getMinutes()).slice(
                -2
              )
            }}
            -
            {{
              ("0" + new Date(bookingDetail.bookingEnd).getHours()).slice(-2)
            }}:{{
              ("0" + new Date(bookingDetail.bookingEnd).getMinutes()).slice(-2)
            }}<br />

            <b>Poznámka</b>: {{ bookingDetail.note }}<br />

            <div class="h4 mt-4">Místa:</div>

            <ul>
              <li v-for="(asset, i) in bookingDetail.assets" :key="i">
                {{ asset.name }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">

            <button type="button" @click="remove(bookingDetail._id)" class="btn btn-danger">Zrušit!</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="calendar"></div>
  </div>
</template>

<script>
import { getBookingsList, deleteReservation } from "../services/backend";
// import { checkAuth, signInCallback, renew } from "../services/auth";
import { checkAuth } from "../services/auth";
import { Modal } from "bootstrap";

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import "@fortawesome/fontawesome-free/css/all.css"; // needs additional webpack config!

export default {
  name: "HelloWorld",
  async mounted() {
    // if (window.location.hash){
    //   const hash = window.location.hash.substring(1);
    //   if(hash == 'sc'){
    //     console.log(renew());
    //   }
    //   return false;
    // }
    // //
    // // } else {
    // //   console.log("Check Auth")
    // //   console.log(await checkAuth());
    // //   console.log("Check Auth done")
    // // }
    //
    // const urlParams = new URLSearchParams(window.location.search);
    // if (urlParams.get("code")) {
    //   console.log('signInCallback');
    //   signInCallback();
    //   window.history.replaceState("", "", window.location.origin + window.location.pathname);
    //   // window.location.href =
    // } else {
    //   console.log("Check Auth")
    //   console.log(await checkAuth());
    //   console.log("Check Auth done")
    // }

    console.log(await checkAuth());


    this.modal = new Modal(document.getElementById("exampleModal"));

    console.log("make calendar");
    this.loadCalendar();

  },
  data: () => ({
    bookings: null,
    bookingDetail: null,
    modal: null,
    calendar: null
  }),
  props: {
    msg: String
  },
  methods: {
    async loadCalendar(){
      this.bookings = await getBookingsList();
      this.calendar = new Calendar(document.getElementById("calendar"), {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
        },
        // themeSystem: 'bootstrap',
        locale: "cs",
        firstDay: 1,
        nowIndicator: true,
        slotMinTime: "14:00:00",
        slotMaxTime: "22:00:00",
        height: 850,
        // height: '80%',
        allDaySlot: false,
        expandRows: true,
        eventClick: function(info) {
          this.setBookingDetail(info.event.extendedProps);
        }.bind(this),
        buttonText: {
          today: "dnes",
          month: "měsíc",
          week: "týden",
          day: "den",
          list: "list"
        },
        // bootstrapFontAwesome: {
        //   close: 'fa-times',
        //   prev: 'fa-chevron-left',
        //   next: 'x bi bi-alarm',
        //   prevYear: 'fa-angle-double-left',
        //   nextYear: 'fa-angle-double-right'
        // },

        businessHours: {
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday - Thursday

          startTime: "14:00", // a start time (10am in this example)
          endTime: "22:00" // an end time (6pm in this example)
        },
        events: this.bookingsToEvents()
      });
      this.calendar.render();
    },
    setBookingDetail(booking) {
      console.log(booking);
      this.bookingDetail = booking;
      this.modal.show();
    },
    calc(duration) {
      return { hours: Math.floor(duration / 60), minutes: duration % 60 };
    },
    bookingsToEvents() {
      // let events = {};
      this.bookings.forEach(booking => {
        booking.hours = Math.floor(booking.duration / 60);
        booking.minutes = booking.duration % 60;
        booking.title = `${booking.user.fullName} (${booking.assets.length}x)`;
        booking.start = booking.bookingStart;
        booking.end = booking.bookingEnd;
      });
      // console.log(bookings)
      return this.bookings;
    },
    async remove(id){
      if (confirm('Opravdu chcete smazat vybranou rezervaci?')) {
        if(await deleteReservation(id)){
          this.modal.hide();
          this.loadCalendar();
        }
      }
    },
    test(bookings) {
      bookings.forEach(booking => {
        booking.hours = Math.floor(booking.duration / 60);
        booking.minutes = booking.duration % 60;
      });
      // console.log(bookings)
      return bookings;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
