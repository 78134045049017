export async function getBookings(date = null) {
  if (date == null) date = new Date();
  const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  const url = new URL(process.env.VUE_APP_BOOKING_URL+"/getBook");
  const params = new URLSearchParams();
  params.append("day", dateString);
  url.search = params;
  const response = await fetch(url, {
    method: "GET",
    headers: { 'Auth-Token': process.env.VUE_APP_BOOKING_AUTH_TOKEN },
  });

  return response.json();
}

export async function getBookingsList() {

  const url = new URL(process.env.VUE_APP_BOOKING_URL+"/getReservationsList");

  const response = await fetch(url, {
    method: "GET",
    headers: { 'Auth-Token': process.env.VUE_APP_BOOKING_AUTH_TOKEN },
  });

  return response.json();
}

export async function deleteReservation(id){
  const url = new URL(process.env.VUE_APP_BOOKING_URL+"/deleteReservation");
  const params = new URLSearchParams();
  params.append("id", id);
  url.search = params;
  const response = await fetch(url, {
    method: "DELETE",
    headers: { 'Auth-Token': process.env.VUE_APP_BOOKING_AUTH_TOKEN },
  });

  return response.ok;
}


// export async getAssets() {
//   const url = new URL(process.env.VUE_APP_BOOKING_URL+"/getAssets");
//   fetch(url, {
//     method: "GET"
//   })
//     .then(response => response.json())
//     .then(data => (this.assets = data));
// },
